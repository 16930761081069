<template>
  <div class="team">
    <div class="team-info">
      <h1>Nosso time</h1>
      <div class="team-texts">
        <p>
          Na Crew, somos uma equipe apaixonada e diversificada, unida por um
          propósito comum: capacitar indivíduos e empresas a alcançar seus
          objetivos de maneira eficiente e criativa. Nossa equipe é composta por
          talentos de todas as áreas, desde desenvolvedores de software e
          designers gráficos até especialistas em atendimento ao cliente e
          estrategistas de negócios.
        </p>
        <p>
          O que nos une é o compromisso com a excelência, a inovação e o apoio à
          nossa comunidade. Estamos sempre buscando formas de aprimorar nossos
          serviços e proporcionar a melhor experiência possível para nossos
          clientes. Acreditamos que a diversidade de perspectivas é fundamental
          para o nosso sucesso, e valorizamos a inclusão e a colaboração em
          nosso ambiente de trabalho.
        </p>
        Queremos que você conheça as pessoas por trás da Crew, suas paixões,
        suas habilidades e seu compromisso com a qualidade. Nossa equipe está
        aqui para ajudá-lo a atingir seus objetivos e tornar sua jornada conosco
        uma experiência inesquecível.
      </div>
      <strong>
        Conheça nossos membros e saiba como cada um deles contribui para o nosso
        sucesso. Estamos ansiosos para trabalhar ao seu lado e fazer parte da
        sua jornada para o sucesso.
      </strong>
    </div>
    <div class="team-slideshow">
      <img src="../assets/img/team/ceo.png" alt="Time" />
    </div>
  </div>
</template>

<script>
import "@/styles/pages/AppTeam.scss";

export default {
  name: "AppTeam",
};
</script>
