<template>
  <div class="contact">
    <div class="contact-ilustration">
      <img src="../assets/img/hero/contact.png" alt="Contato" />
      <h1>Entre em contato com nossa equipe</h1>
      <p>
        Fique a vontade para pergunta sobre quaisquer assunto, nosso time esta
        pronto para lhe atender e tirar seja qual for sua dúvida. Também estamos
        abertos a sugestões e criticas, contamos com você! ;)
      </p>
    </div>

    <div class="contact-form">
      <form action="">
        <input name="nome" placeholder="Nome completo" type="text" />
        <input name="email" placeholder="E-mail" type="text" />
        <input name="telefone" placeholder="Telefone" type="text" />
        <textarea name="mensagem" placeholder="Mensagem" type="text" />
        <button type="submit">Enviar mensagem</button>
      </form>
    </div>
  </div>
  <div class="information">
    <h1>Informações de contato</h1>
    <p>
      contato@platfcrew.com.br <br />
      Rua teresina 137,<br />
      Retiro Volta Redonda - RJ <br />+55 (24) 98122-2010 <br />+55 (24)
      3337-5329
    </p>
  </div>
</template>

<script>
import "@/styles/pages/AppContact.scss";
export default {
  name: "AppContact",
};
</script>
