<template>
  <div class="plan-card-container">
    <img src="../assets/img/cards-icons/plan.png" alt="plano" />
    <h1 class="plan-title">{{ title }}</h1>
    <button>Assinar</button>
    <strong>R${{ cost }}</strong>
  </div>
</template>

<script>
import "@/styles/components/AppPlanCard.scss";
export default {
  name: "AppInfoCard",
  props: {
    title: String,
    benefits: String,
    cost: String,
  },
  data: function () {
    return {};
  },
};
</script>
