<template>
  <div class="top-content">
    <div class="hero-container">
      <div class="hero-texts">
        <h1 class="hero-title">Mostre sua ideia para o mundo!</h1>
        <p class="hero-text">
          Monte hoje mesmo a sua tripulação e embarque rumo ao sucesso
          <br />Construa uma equipe com pessoas que pensam com você e também
          sonham alcançar grandes patamares
        </p>
        <div class="hero-action-buttons">
          <button class="join">Juntar-se</button>
          <button class="know-more">Saiba Mais</button>
        </div>
      </div>

      <div class="hero-image">
        <img src="../assets/img/hero/hero-image.png" alt="" />
      </div>
    </div>
  </div>

  <div class="info-section">
    <h1>A plataforma</h1>
    <p>
      A plataforma Crew tem como objetivo unir pessoas que compactuam de um
      mesmo pensamento e sonhos relacionados a área profissional e do
      empreendedorismo nossa missão é unir pessoas e ajuda-las a desenvolver
      seus projetos pessoais tornando-os grandes empresas a fim de desenvolver a
      economia nacional
    </p>
  </div>

  <div class="cards-section">
    <div class="card-grid">
      <AppInfoCard
        v-for="cards in infoCards"
        v-bind:key="cards"
        :icon="cards.icon"
        :title="cards.title"
        :description="cards.description"
      />
    </div>
  </div>

  <div class="hook-section">
    <div class="hook-image">
      <img src="../assets/img/hero/Yacht-image.png" alt="Yacth" />
    </div>
    <div class="hook-text">
      <h1>Junte-se a uma tripulação e alcance grandes feitos !</h1>
      <hr />
      <p>
        Faça parte de um grupo de projeto e ajude a tornar essa ideia possivel.
        Inscreva-se em nossa plataforma e comece a construir seus sonhos !
      </p>
    </div>
  </div>

  <div class="plans-section">
    <h1>Nossos planos</h1>

    <div class="plan-cards">
      <AppPlanCard title="Gratuito" benefits="" cost="00,00" />
      <AppPlanCard title="Starter" benefits="" cost="00,00" />
      <AppPlanCard title="Business" benefits="" cost="00,00" />

      <p>
        Observe bem os termos antes de selecionar um plano de assinatura, esteja
        ciente de todos os itens e cobranças
      </p>
    </div>
  </div>
</template>

<script>
import AppInfoCard from "@/components/AppInfoCard.vue";
import AppPlanCard from "@/components/AppPlanCard.vue";
import "@/styles/pages/AppLanding.scss";

import IcConsulting from "@/assets/img/cards-icons/ic_consulting.png";
import IcDash from "@/assets/img/cards-icons/ic_dash.png";
import IcIdea from "@/assets/img/cards-icons/ic_idea.png";
import IcMethods from "@/assets/img/cards-icons/ic_methods.png";
import IcMoney from "@/assets/img/cards-icons/ic_money.png";
import IcPeople from "@/assets/img/cards-icons/ic_people.png";
import IcPlus from "@/assets/img/cards-icons/ic_plus.png";
import IcSearch from "@/assets/img/cards-icons/ic_search.png";

export default {
  name: "AppLanding",
  components: { AppInfoCard, AppPlanCard },
  data: function () {
    return {
      infoCards: [
        {
          icon: IcSearch,
          title: "Busque projetos",
          description:
            "Encontre oportunidades e projetos alinhados com seus interesses.",
        },
        {
          icon: IcPlus,
          title: "Crie projetos",
          description:
            "Inicie suas próprias iniciativas e dê vida às suas ideias.",
        },
        {
          icon: IcIdea,
          title: "Compartilhe ideias",
          description:
            "Colabore e troque ideias com outros membros da comunidade.",
        },
        {
          icon: IcDash,
          title: "Estatisticas",
          description:
            "Acesse dados e insights para tomar decisões informadas.",
        },
        {
          icon: IcMethods,
          title: "Metodologias ageis",
          description:
            "Implemente abordagens ágeis para melhorar a produtividade.",
        },
        {
          icon: IcMoney,
          title: "Investimentos",
          description: "Descubra oportunidades de investimento e parcerias.",
        },
        {
          icon: IcConsulting,
          title: "Consultoria",
          description:
            "Obtenha orientação especializada para impulsionar seus projetos.",
        },
        {
          icon: IcPeople,
          title: "Social",
          description:
            "Conecte-se com pessoas e construa uma rede de contatos.",
        },
      ],
    };
  },
};
</script>
