<template>
  <div class="container">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "AppRouter",
};
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
}
</style>
