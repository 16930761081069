<template>
  <h1>Sobre a Crew: Unindo Visionários e Transformando Sonhos em Realidade</h1>

  <p>
    Bem-vindo à Crew, o ponto de encontro para visionários e empreendedores que
    buscam transformar ideias inovadoras em empreendimentos de sucesso. Tudo
    começou em meados de 2020, quando o jovem estudante Marcelo Silva Corrêa,
    apaixonado por tecnologia e empreendedorismo, decidiu tornar seu sonho de
    empreender uma realidade.
  </p>

  <h3>Da Inspiração à Inovação: O Nascimento da Crew</h3>
  <p>
    Marcelo, motivado por sua paixão por conectar mentes criativas, teve a visão
    de criar uma plataforma única, uma rede social voltada especificamente para
    empreendedores. Assim, a Crew nasceu, impulsionada pelo desejo de unir
    pessoas com habilidades diversas e inspirações complementares para desbravar
    o emocionante mundo dos negócios.
  </p>

  <h3>Conectando Talentos, Criando Oportunidades</h3>
  <p>
    Na Crew, acreditamos que as melhores ideias nascem da colaboração e da
    diversidade. Nossa plataforma proporciona um ambiente dinâmico e inovador,
    onde empreendedores podem se conectar, compartilhar conhecimentos e formar
    equipes excepcionais para a gestão de novos projetos e a criação de empresas
    promissoras. Explorando o Ecossistema Empreendedor: O que a Crew Oferece
  </p>

  <AppInfoCard
    v-for="cards in infoCards"
    v-bind:key="cards"
    :icon="cards.icon"
    :title="cards.title"
    :description="cards.description"
  />
</template>

<script>
import AppInfoCard from "@/components/AppInfoCard.vue";

export default {
  name: "AppLanding",
  components: { AppInfoCard },
  data: () => {
    return {};
  },
};
</script>
