<template>
  <div class="card-container">
    <img :src="icon" :alt="title" />
    <h1>{{ title }}</h1>
    <p>{{ description }}</p>
  </div>
</template>

<script>
import "@/styles/components/AppInfoCard.scss";
export default {
  name: "AppInfoCard",
  props: {
    icon: Image,
    title: String,
    description: String,
  },
  data: function () {
    return {};
  },
};
</script>
