import * as VueRouter from "vue-router";
import AppRouter from "@/views/AppRouter.vue";
import AppLanding from "@/views/AppLanding.vue";
import AppContact from "@/views/AppContact.vue";
import AppAbout from "@/views/AppAbout.vue";
import AppTeam from "@/views/AppTeam.vue";

const routes = [
  {
    path: "/",
    component: AppRouter,
    children: [
      { path: "", component: AppLanding },
      { path: "contato", component: AppContact },
      { path: "time", component: AppTeam },
      { path: "sobre", component: AppAbout },
    ],
  },
];

const Router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes,
});

export default Router;
