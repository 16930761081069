<template>
  <div class="general-header-area">
    <div class="AppHeader">
      <img src="@/assets/img/logo.png" alt="Crew" />
      <div class="navbar">
        <div class="nav">
          <div class="nav-itens">
            <router-link v-for="item in navItens" :to="item.url" :key="item">{{
              item.label
            }}</router-link>
          </div>

          <div class="nav-buttons">
            <button class="signup">Cadastro</button>
            <button class="signin">Entrar</button>
          </div>
        </div>

        <div class="nav-downloads">
          <img
            class="dlw-icon"
            src="@/assets/img/icons/dlw-android.png"
            alt="Android"
          />
          <img
            class="dlw-icon"
            src="@/assets/img/icons/dlw-ios.png"
            alt="iOS"
          />
        </div>
      </div>
    </div>
    <div class="listel">Versão Pré-Aplha</div>
  </div>
</template>

<script>
import "@/styles/AppHeader.scss";
export default {
  name: "AppHeader",
  data: function () {
    return {
      navItens: [
        {
          id: 0,
          label: "Ínicio",
          url: "",
        },
        {
          id: 1,
          label: "A plataforma",
          url: "",
        },
        {
          id: 2,
          label: "Nossos Planos",
          url: "",
        },
        {
          id: 3,
          label: "Contato",
          url: "contato",
        },
      ],
    };
  },
};
</script>
