import * as Vue from "vue";
import Router from "./routes";

import AppLandingPage from "@/App.vue";

import "@/styles/Global.scss";

const App = Vue.createApp(AppLandingPage);

App.use(Router);
App.mount("#app");
