<template>
  <div class="AppFooter">
    <div class="footer-infos">
      <div class="top-info">
        <img src="@/assets/img/logo-footer.png" alt="Crew" />
        <strong
          ><b>Faça parte agora mesmo!</b> <br />
          Alcance o mundo com seus projetos !</strong
        >
      </div>

      <strong>©2023 Plataforma Crew </strong>
    </div>

    <div class="nav-content">
      <div class="nav">
        <div class="sections">
          <div class="nav-section" v-for="item in navItens" :key="item">
            <strong>{{ item.title }}</strong>
            <router-link
              v-for="link in item.links"
              :key="link.id"
              :to="link.url"
              @click="navigate(link.isExternal, link.url)"
              >{{ link.label }}</router-link
            >
          </div>
        </div>

        <div class="social-links">
          <img
            @click="redirectExternalLinks(social.url)"
            class="social-icon"
            v-for="social in socialLinks"
            :key="social.id"
            :src="social.icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/styles/AppFooter.scss";

import icFacebook from "@/assets/img/icons/social/ic_facebook.png";
import icInsta from "@/assets/img/icons/social/ic_insta.png";
import icLinkedin from "@/assets/img/icons/social/ic_linkedin.png";
import ictwitter from "@/assets/img/icons/social/ic_twitter.png";
import icYoutube from "@/assets/img/icons/social/ic_youtube.png";

export default {
  name: "AppFooter",
  methods: {
    redirectExternalLinks(url) {
      if (url !== null || url !== "") {
        window.open(url, "_blank");
      }
    },
    navigate(isExternal, url) {
      if (isExternal) {
        this.redirectExternalLinks(url);
      } else {
        this.$router.push(url);
      }
    },
  },
  data: function () {
    return {
      socialLinks: [
        {
          id: 0,
          icon: ictwitter,
          url: "https://twitter.com/i/flow/login?redirect_after_login=%2FPlatfCrew",
        },
        {
          id: 1,
          icon: icYoutube,
          url: "https://www.youtube.com/@GrupoCrew/videos",
        },
        {
          id: 2,
          icon: icLinkedin,
          url: "",
        },
        {
          id: 3,
          icon: icInsta,
          url: "https://www.instagram.com/platfcrew/",
        },
        {
          id: 4,
          icon: icFacebook,
          url: "",
        },
      ],
      navItens: [
        {
          title: "A Empresa",
          links: [
            {
              id: 0,
              label: "Sobre Nós",
              url: "sobre",
              isExternal: false,
            },
            {
              id: 1,
              label: "Equipe",
              url: "time",
              isExternal: false,
            },
            {
              id: 2,
              label: "Localizações",
              url: "",
              isExternal: false,
            },
            {
              id: 3,
              label: "Contato",
              url: "contato",
              isExternal: false,
            },
          ],
        },
        {
          title: "Produto",
          links: [
            {
              id: 0,
              label: "Planos",
              url: "",
              isExternal: false,
            },
            {
              id: 1,
              label: "FAQ",
              url: "",
              isExternal: true,
            },
            {
              id: 2,
              label: "Suporte",
              url: "",
              isExternal: true,
            },
            {
              id: 3,
              label: "Paises",
              url: "",
              isExternal: true,
            },
          ],
        },
        {
          title: "Social",
          links: [
            {
              id: 0,
              label: "Newsletter",
              url: "https://whatsapp.com/channel/0029Va6bymL4IBhE7pYf821y",
              isExternal: true,
            },
          ],
        },
      ],
    };
  },
};
</script>
