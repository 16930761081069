<template>
  <div class="app-landing">
    <AppHeader />
    <AppRouter />
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from "@/components/Scaffold/AppFooter.vue";
import AppHeader from "@/components/Scaffold/AppHeader.vue";
import AppRouter from "@/views/AppRouter.vue";

export default {
  name: "AppLandingPage",
  components: {
    AppHeader,
    AppRouter,
    AppFooter,
  },
};
</script>
